<template>
    <div class="image-recognition-content">
        <div class="list-box">
            <div class="lim-top-upload">
                <DragUpload 
                    @uploadSuccess="uploadSuccess" 
                    @previewImage="previewImage" 
                    :imageList="imageList" 
                    :uploadSuccessItem="uploadSuccessItem"/>
            </div>
            <!-- <div class="image-list"> -->
                <div 
                    v-for="(item,index) in imageList" 
                    :key="index" 
                   
                    :class="['image-item',item.checked ? 'checked' : '','image-item'+item.id]" >
                    <img  
                        :src="item.imageUrl"  
                        :ref="'image'+ item.id" 
                        @click="clickImg(item)"
                        alt="" >
                    <!-- <el-image
                        :ref="'image'+ item.id"
                        :src="item.imageUrl"
                        :fit="'cover'"
                        @click="clickImg(item)"
                        :preview-src-list="previewList">
                    </el-image> -->
                    <el-checkbox v-model="item.checked" @change="changeChecked(item)" v-if="!item.checked" ></el-checkbox>
                    <div class="checked-status" v-else @click="changeChecked(item,'switch')">
                        <i class="iconfont guoran-tongyichicun-kaiqi1"></i>
                    </div>
                </div>
                <!-- <div class="preview-box">
                    <div class="full-screen-btn iconfont guoran-fangda" @click="handleFullScreen"></div>
                    <div class="delete-image-btn iconfont guoran-tongyichicun-18-13-shanchu" @click="deleteImage"></div>
                    <div  class="full-screen-image">
                        <el-image
                            v-if="isFullScreen"
                            :src="currentImage.imageUrl"
                            :fit="'cover'">
                        </el-image>
                    </div>
                    
                </div> -->
            <!-- </div> -->
        </div>
        <div class="checked-operation" v-if=" checkedInfo.count > 0">
            <div class="content">
                <div @click="selectAll" class="dhb-cell">{{$t('intentQa.allSelect')}}</div>
                <div @click="deleteSelected" class="dhb-cell">{{$t('common.deleteText')}}</div>
                <div class="dhb-cell-with-number">
                    <span @click="cancelSelectAll">{{$t('intentQa.cancelSelect')}}</span>
                    <span class="dhb-cell-count">{{ checkedInfo.count }}</span>
                </div>
            </div>
        </div>
        <errorDialog @closeEvent="deleteImagePopup = false" v-if="deleteImagePopup">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('intentQa.deleteImage')}}</div>
                <div>{{$t('intentQa.deleteImageTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="deleteImagePopup = false"
                    size="small"
                    style="font-size:14px "
                    round
                    plain>{{$t('common.cancel')}}</el-button>
                <el-button
                    @click="deleteImageFn"
                    round
                    style="background-color: #fe5965;color: white;font-size:14px;border-color:#fe5965"
                    size="small">{{$t('common.deleteText')}}</el-button>
            </div>
        </errorDialog>
        <imageView
            v-if="showViewer"
            :previewList.sync="previewList"
            :showViewer.sync="showViewer"
            :currentImage="currentImage"
            :isCanDel="true"
            @deleteImage="deleteImage"
            @switchImgage="switchImgage"
            @bindKeydown="bindKeydown">
        </imageView>
    </div>
</template>

<script>
import DragUpload from './DragUpload.vue';
import errorDialog from "../../../components/errorDialog.vue";
// import screenfull from "screenfull"
import imageView from '../../../components/imageView.vue'

export default {
    data(){
        return {
            imageList:[],
            checkedInfo:{
                checkedIds:[],
                count:0,
            },
            deleteImagePopup:false,
            previewList:[], // 预览大图
            currentImage:{
                imageUrl:"",
                id:'',
                sortNum:0
            }, // 当前的图片
            intentId:'',
            isPreviewDel:false, // 是否是预览时的删除,
            uploadSuccessItem:{},
            showViewer:false
        }
    },
    components:{DragUpload, errorDialog, imageView},
    watch:{
        imageList:{
            handler(n){
                // n.forEach((item,index) => {
                    // item.sortNum = index;
                // })
                this.$emit('updateImagelist',n)
            },
            deep:true,
            immediate:true
        },
    },
    mounted(){
        this.intentId = this.$route.query.intentId
        this.getImgageList();
       
    },
   
    methods:{
        // 获取意图图片列表
        getImgageList(){
            this.FetchGet( this.requestUrl.intent.getImgageList + '?intentId=' + this.intentId).then((res) => {
                if (res.code === "0") {
                    this.previewList = [];
                    res.data.forEach((item,index) => {
                        item.sortNum = index;
                        if(this.checkedInfo.checkedIds.indexOf(item.id) !== -1){
                            item.checked = true;
                        } else {
                            item.checked = false;
                        }
                        // if(!item.checked){
                        //     item.checked = false;
                        // }
                        item.sortNum = index;
                        this.previewList.push(item.imageUrl)
                        if(JSON.stringify(this.uploadSuccessItem) !== '{}'){
                            if(this.uploadSuccessItem.imageUrl === item.imageUrl){
                                this.uploadSuccessItem.id = item.id;
                            }
                        }
                    })
                    this.imageList = res.data;
                    this.$forceUpdate();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        
        
        // 点击列表中的图片
        clickImg(item){
            this.previewList = [];
            this.imageList.forEach((item,index) => {
                this.previewList.push(item.imageUrl)
            })
            this.currentImage = item;
            this.showViewer = true;
        },
        // 预览时切换图片 , 获取当前图片的信息
        switchImgage(){
            let imgDom = null,imgUrl = '';
            imgDom = document.querySelector('.my-image-view .el-image-viewer__canvas img');
            imgUrl = imgDom.getAttribute('src');
            this.imageList.forEach(item => {
                if(item.imageUrl === imgUrl){
                    this.currentImage = item;
                }
            }) 
        },
         // 删除预览时的图片
        deleteImage(){
            console.log('删除预览时的图片',this.currentImage);
            this.isPreviewDel = true;
            this.deleteImagePopup = true;
            // this.deleteImageFn()
        },
        // 全屏查看大图时 , 快捷键的处理
        bindKeydown(e){
            if(e.code === 'ArrowRight'){
                if(this.currentImage.sortNum + 1 >= this.imageList.length){
                    this.currentImage = this.imageList[0];
                } else {
                    this.currentImage = this.imageList[this.currentImage.sortNum + 1]
                }
            } else if(e.code === 'ArrowLeft'){
                if(this.currentImage.sortNum - 1 < 0){
                    this.currentImage = this.imageList[this.imageList.length - 1];
                } else {
                    this.currentImage = this.imageList[this.currentImage.sortNum - 1]
                }
            }
        },
        // 上传列表的预览图片
        previewImage(data){
            let currentObj = {}
            this.imageList.forEach(item => {
                if(item.id === data.id){
                    currentObj = item;
                }
            })
            this.clickImg(currentObj);
        },
           /**
        * 子组件 [DragUpload] 调用 上传文件后回调
        * @method uploadSuccess
        * @param {object} file 上传文件成功后信息
        * @return
        */
        uploadSuccess(file) {
            this.uploadSuccessItem = file;
            console.log(file,'上传文件成功后信息----',this.imageList);
            let flag = true;
            this.imageList.forEach(item => {
                if(item.imageName === file.name){
                    flag = false;
                }
            })
            if(!flag) return false;
            this.FetchPost(this.requestUrl.intent.addImage,{
                intentId:this.intentId,
                url:file.imageUrl,
                name:file.name,
                size:file.size,
                // urls:[file.imageUrl],
                apiKey:this.$route.query.apiKey,
                botId:this.$route.query.bid,
            }).then((res) => {
                if (res.code === "0") {
                    this.getImgageList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 是否选中的处理
        changeChecked(item,isSwitch){
            if(isSwitch){
                item.checked = false;
            }
            if(item.checked){
                this.checkedInfo.checkedIds.push(item.id)
            } else {
                this.checkedInfo.checkedIds.splice(this.checkedInfo.checkedIds.indexOf(item.id),1)
            }
            this.checkedInfo.checkedIds = Array.from(new Set(this.checkedInfo.checkedIds))
            this.checkedInfo.count =  this.checkedInfo.checkedIds.length;
        },
        // 处理全选/全不选
        handlerChecked(checked){
            this.checkedInfo.checkedIds = [];
            this.imageList.forEach(item => {
                item.checked = checked;
                if( item.checked){
                    this.checkedInfo.checkedIds.push(item.id);
                }
            })
            this.checkedInfo.count =  this.checkedInfo.checkedIds.length;
        },
        // 全选
        selectAll(){
            this.handlerChecked(true);
        },
        // 删除选中数据
        deleteSelected(){
            this.isPreviewDel = false;
            this.deleteImagePopup = true;
        },
        // 取消删除
        cancelSelectAll(){
            this.handlerChecked(false);
        },
        // 删除图片--调用接口
        deleteImageFn(){
            this.checkedInfo.checkedIds = [];
            if(!this.isPreviewDel){
                this.imageList.forEach((item,index) => {
                    if(item.checked){
                        this.checkedInfo.checkedIds.push(item.id)
                    }
                })
            } else {
                this.imageList.forEach((item,index) => {
                    if(item.id === this.currentImage.id){
                        this.checkedInfo.checkedIds = [item.id]
                    }
                }) 
            }
            let obj = {
                list:this.checkedInfo.checkedIds
            }
            this.FetchDelete2(this.requestUrl.intent.deleteImage,'',{data:obj}).then((res) => {
                if (res.code === "0") {
                    if(this.isPreviewDel){
                        this.imageList.forEach((item,index) => {
                            if(item.id === this.currentImage.id){
                                this.checkedInfo.checkedIds = [item.id]
                                if(item.id === this.currentImage.id){
                                    if(index + 1 >= this.imageList.length){
                                        this.currentImage = this.imageList[0]; 
                                    } else {
                                        this.currentImage = this.imageList[index + 1];
                                    }
                                }
                                this.imageList.splice(index,1);
                            } 
                            
                        }) 
                        this.previewList = [];
                        if(this.imageList.length === 0){
                            this.previewList = [];
                            this.showViewer = false;
                        } else {
                            this.imageList.forEach(item => {
                                this.previewList.push(item.imageUrl)
                            })
                        }
                    } else {
                        this.getImgageList();
                    }
                    this.checkedInfo = {
                        checkedIds:[],
                        count:0
                    } 
                    if(this.currentImage.imageUrl && this.currentImage.imageUrl !== ''){
                        let imgDom = document.querySelector('.my-image-view .el-image-viewer__canvas img');
                        imgDom.setAttribute('src',this.currentImage.imageUrl)
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
            this.deleteImagePopup = false;
        },
    }
}
</script>

<style lang="less" scoped>
.image-recognition-content{
    padding: 36px;
    font-family: Microsoft YaHei;
    display: flex;
    position: relative;
    height: calc(100vh - 230px);

    .list-box{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .lim-top-upload{
        width: 168px;
        height: 168px;
        margin-right:36px;
    }
    // .image-list{
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-content: flex-start;
        .image-item{
            width: 168px;
            height: 168px;
            border-radius: 5px;
            cursor: pointer;
            margin-right:36px;
            margin-bottom: 36px;
            position: relative;
            border: 2px solid #fff;
            img{
                width: 100%;
                height: 100%;
                border-radius: 5px;
               
                object-fit: cover;
            }
            .el-checkbox{
                position: absolute;
                right: 5px;
                bottom: 5px;
                display: none;
            }
            .checked-status{
                position: absolute;
                right: -2px;
                bottom: -2px;
                width: 0;
                height: 0;
                border-bottom: 36px solid #366AFF;
                border-left: 36px solid transparent;
                .guoran-tongyichicun-kaiqi1{
                    position: absolute;
                    right: 2px;
                    bottom: -32px;  
                    color: #fff;
                    font-size: 14px;
                }
            }
            &.checked{
                border: 2px solid #366AFF;
            }
            &:hover{
                .el-checkbox{
                    display: block;
                }
            }
        }
       
    // }
    .checked-operation{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 32px;
        width: 100%;
        .content{
            width: 292px;
            height: 44px;
            background: #344473;
            opacity: 0.5;
            border-radius: 5px;
            color: #fff;
            display: flex;
            align-items: center;
            .dhb-cell {
                height: 14px;
                line-height: 14px;
                font-size: 13px;
                flex: 1;
                border-right: solid 1px lightgrey;
                cursor: pointer;
            }

            .dhb-cell:hover {
                font-weight: bolder;
            }

            .dhb-cell-with-number {
                height: 14px;
                line-height: 14px;
                font-size: 13px;
                flex: none;
                width: 110px;
                cursor: pointer;

                .dhb-cell-count {
                    font-size: 12px;
                    margin-left: 6px;
                    height: 20px;
                    background: #ECF1FF;
                    border-radius: 10px;
                    padding: 0 6px;
                    color: #366AFF;
                    font-weight: bolder;
                }
            }

            .dhb-cell-with-number:hover {
                font-weight: 700;
            }
        }
    }
    
}
</style>