<template>
    <div
        v-if="visible"
        class="wrapper"
        :style="{position:'fixed',top:isAddQa ? 'auto' : position.y +'px',bottom: isAddQa ? position.y + 'px': 'auto',left:position.x+'px'}">
        <!-- <div v-if="!mockList.length" class="empty">无搜索结果</div>
        <div
            v-for="(item,i) in mockList"
            :key="item.id"
            class="item"
            :class="{'active': i === index}"
            ref="usersRef"
            @click="clickAt($event,item)"
            @mouseenter="hoverAt(i)">
            <div class="name">{{item.name}}</div>
        </div> -->
        <div class="rule-config-header-title">
            <div class="rule-config-header-right">
                <div class="title">变量</div>
                <div class="title-tips">从下方选择要附加至内容的变量，之后将转换为相关数据</div>
                <div class="iconfont guoran-shanchu" @click.stop="closeFn"></div>
            </div>
        </div>
        <div class="rule-config-content">
            <div v-for="(item,index) in dataList" :key="index">
                <div class="class-name" v-if="item.entityList && item.entityList.length > 0">{{item.className}}</div>
                <div class="entity-content">
                    <div @click="clickAt($event,entityItem)" :class="['entity-item',item.checked ? 'checked' : '']" v-for="(entityItem) in item.entityList" :key="entityItem.id">{{entityItem.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
    const mockData = [
    { name: 'HTML', id: 'HTML' },
    { name: 'CSS', id: 'CSS' },
    { name: 'Java', id: 'Java' },
    { name: 'JavaScript', id: 'JavaScript' }
    ]
    export default {
    name: 'AtDialog',
    props: {
        visible: Boolean,
        position: Object,
        queryString: String,
        isAddQa:Boolean,
    },
    data () {
        return {
            users: [],
            index: -1,
            mockList: mockData,
            dataList:[]
        }
    },
    watch: {
        queryString: {
          handler(val){
                console.log(val,'关键词');
                this.searchData()
                // this.getData('@');
                // val ? this.mockList = mockData.filter(({ name }) => name.startsWith(val)) : this.mockList = mockData.slice(0)
          },
          immediate:true
        },
        visible(n){
        //     console.log(n,'visible');
            if(n){
                this.getData();
            }
        }
    },
    mounted () {
        console.log('mounted');
        document.addEventListener('keyup', this.keyDownHandler)
        // this.dataList = [];
    },
    destroyed () {
        document.removeEventListener('keyup', this.keyDownHandler)
    },
    methods: {
        keyDownHandler (e) {
        if (e.code === 'Escape') {
            this.$emit('onHide')
            return
        }
        // 键盘按下 => ↓
        if (e.code === 'ArrowDown') {
            if (this.index >= this.mockList.length - 1) {
            this.index = 0
            } else {
            this.index = this.index + 1
            }
        }
        // 键盘按下 => ↑
        if (e.code === 'ArrowUp') {
            if (this.index <= 0) {
            this.index = this.mockList.length - 1
            } else {
            this.index = this.index - 1
            }
        }
        // 键盘按下 => 回车
        if (e.code === 'Enter') {
            if (this.mockList.length) {
                const user = {
                    name: this.mockList[this.index].name,
                    id: this.mockList[this.index].id
                }
                this.$emit('onPickUser', user)
                this.index = -1
            }
        }
        },
        // 选择某个实体
        clickAt (e, item) {

            const user = {
                name: item.name,
                id: item.id
            }
            item.checked = true;
            this.$emit('onPickUser', user)
            this.index = -1
        },
        hoverAt (index) {
            this.index = index
        },
        closeFn(){
            this.$emit('update:visible',false);
        },
        getData(type){
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill + "?botId=" + this.$route.query.bid).then((res) => {
                if (res.code === "0") {
                    this.dataList = [];
                    if(res.data){
                        for(let key in res.data){
                            this.dataList.push({
                                className:key,
                                entityList:res.data[key]
                            })
                        }
                        console.log(this.queryString,'type');
                        // if(type && type === '@'){
                            
                        // } else {
                        //     this.dataList = list;
                        // }
                        console.log(this.dataList,'-----');
                    } else {
                        this.dataList = []
                    }
                    
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        searchData(){
            let list = JSON.parse(JSON.stringify(this.dataList))
            let dataLists = [];
            // this.dataList = [];
            if(this.queryString === ''){
                this.dataList = list;    
            } else {
                dataLists = [];
                console.log(list,'list');
                list.forEach(item => {
                    if(item.entityList.length > 0){
                        let data = [];
                        item.entityList.forEach(v => {
                            let name = v.name;
                            if(name.indexOf(this.queryString) !== -1){
                                console.log(v);
                                data.push(v)
                                console.log(data,'data');
                                dataLists.push({
                                    className:item.className,
                                    entityList:data
                                });
                            }
                        })
                    }
                })
                console.log(dataLists,'dataListsdataListsdataListsdataListsdataLists');
                this.dataList = [...[],...dataLists];
                // if(this.dataList.length === 0){
                //     this.$emit('update:visible',false)
                // }
            }
        }
    }
    }
</script>
 
<style scoped lang="less">
  .wrapper {
    // width: 238px;
    // border: 1px solid #e4e7ed;
    // border-radius: 4px;
    // background-color: #fff;
    // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    // box-sizing: border-box;
    // padding: 6px 0;
    font-family: Microsoft YaHei;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57,63,79,0.21);
    border-radius: 5px;
    padding: 19px 22px 23px 22px;
    font-size: 14px;
    .rule-config-header-title{
        margin-bottom: 13px;
        position: relative;
        .title{
            font-size: 22px;
            color: #000000;
            margin-bottom: 11px;
        }
        .title-tips{
            color: #A9B3C6;
        }
        .guoran-shanchu{
            position: absolute;
            width: 28px;
            height: 28px;
            background: #F5F7FB;
            border-radius: 50%;
            color: #616161;
            font-size: 16px;
            text-align: center;
            line-height: 28px;
            cursor: pointer;
            right: 0;
            top: 0;
        }
    }
    .rule-config-content{
        width: 340px;
        height: 281px;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        overflow-y: auto;
        padding: 5px;
        .class-name{
            height: 37px;
            background: #E6EEFF;
            border-radius: 1px;
            color: #000000;
            line-height: 37px;
            padding-left: 15px;
            margin-bottom: 12px;
            text-align: left;
        }
        .entity-content{
            margin-left: 17px;
            display: flex;
            flex-wrap: wrap;
            .entity-item{
                margin-bottom: 12px;
                margin-right: 8px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 12px;
                color: #000;
                font-size: 14px;
                padding: 2px 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &.checked{
                    background-color: #F5F7FB;
                }
            }
        }
        
    }
  }
  .empty{
    font-size: 14px;
    padding: 0 20px;
    color: #999;
  }
  .item {
    font-size: 14px;
    padding: 0 20px;
    line-height: 34px;
    cursor: pointer;
    color: #606266;
    &.active {
      background: #f5f7fa;
      color: blue;
      .id {
        color: blue;
      }
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    .id {
      font-size: 12px;
      color: rgb(83, 81, 81);
    }
  }
</style>